import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Link } from '@theme/components';

export const Description = (props) => {
  const {
    product,
    selectedProduct: { name, absolute_url, pk, attributes },
    index,
    className,
    isNameMultiline
  } = props;

  const baseProduct = product?.extra_data?.variants[0]?.options.find(
    (option) => option.product.pk === pk
  );

  const attributeKeys = Object.keys(attributes || {});

  const defaultParams = baseProduct
    ? Object.keys(baseProduct.product.attributes)
        .map(
          (key) =>
            `${key}=${encodeURIComponent(baseProduct.product.attributes[key])}`
        )
        .join('&')
    : attributeKeys
        .map((key) => `${key}=${encodeURIComponent(attributes[key])}`)
        .join('&');

  const base_absolute_url = `${baseProduct?.product?.absolute_url}?${defaultParams}`;

  return (
    <Link
      href={
        baseProduct?.product?.absolute_url ? base_absolute_url : absolute_url
      }
      data-testid={`${product?.name}-${index}`}
      className={twMerge(
        clsx(
          'h-12 py-1 font-sans text-sm font-medium leading-[1.71] text-black text-opacity-80 lg:text-base lg:leading-[1.5]',
          !isNameMultiline ? 'line-clamp-2' : 'line-clamp-1',
          className
        )
      )}
    >
      {name}
    </Link>
  );
};
