import clsx from 'clsx';
import { ProductItem } from '..';

export const ProductItemDefault = (props) => {
  const {
    ImageWidth,
    ImageHeight,
    mobileImageWidth,
    mobileImageHeight,
    wrapperClassName,
    favIconClassName,
    hidePrice = false,
    isOrderAgain
  } = props;

  return (
    <ProductItem {...props}>
      <ProductItem.Wrapper className={wrapperClassName}>
        <ProductItem.HoverWrapper>
          <ProductItem.Images
            width={ImageWidth}
            height={ImageHeight}
            mobileImageHeight={mobileImageHeight}
            mobileImageWidth={mobileImageWidth}
          />

          <ProductItem.Favorite favIconClassName={favIconClassName} />
          <ProductItem.Add />
          <ProductItem.Badges />
          <ProductItem.ProductContentBadge />
        </ProductItem.HoverWrapper>
        <div className="mb-2 flex max-w-full flex-col justify-between overflow-hidden text-ellipsis lg:mb-[11px]">
          <ProductItem.Description className="mb-2.5 mt-3" />
          <ProductItem.Prices
            priceWrapperClassName="flex-col items-start  min-h-[48px]"
            hidePrice={hidePrice}
          />
        </div>

        <ProductItem.Offers />
        <ProductItem.Select wrapperClassName={`mt-3 lg:mt-2.5 pb-1 lg:pb-2.5 ${isOrderAgain ? "hidden" : ""}`} />
        <ProductItem.ProductCounter />
      </ProductItem.Wrapper>
    </ProductItem>
  );
};
