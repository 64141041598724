'use client';

import { Link } from '@akinon/next/components';
import { Image } from '@akinon/next/components/image';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export const Images = (props) => {
  const {
    selectedProduct,
    product,
    width,
    height,
    mobileImageHeight,
    mobileImageWidth,
    isHover,
    imageUrl
  } = props;
  const { name, productimage_set, absolute_url } = selectedProduct;
  const baseProduct = product?.extra_data?.variants[0]?.options.find(
    (option) => option.product.pk === selectedProduct?.pk
  );

  const attributeKeys = Object.keys(selectedProduct?.attributes || {});

  const defaultParams = baseProduct
    ? Object.keys(baseProduct.product.attributes)
        .map(
          (key) =>
            `${key}=${encodeURIComponent(baseProduct.product.attributes[key])}`
        )
        .join('&')
    : attributeKeys
        .map(
          (key) =>
            `${key}=${encodeURIComponent(selectedProduct.attributes[key])}`
        )
        .join('&');

  if (!width || !height) return null;

  const base_absolute_url = `${baseProduct?.product?.absolute_url}?${defaultParams}`;

  if (!width || !height) return null;

  const primaryImage = productimage_set?.[0]?.image || '/akn-noimage.jpg';
  const secondaryImage = productimage_set?.[1]?.image || null;

  return (
    <Link
      href={
        baseProduct?.product?.absolute_url ? base_absolute_url : absolute_url
      }
      className="relative z-0 flex flex-col"
    >
      {productimage_set?.length > 0 ? (
        <>
          {/* Desktop Hoverable & Badge Images */}
          <div className="relative hidden h-full w-full lg:block">
            {secondaryImage && (
              <Image
                className={twMerge(
                  clsx(
                    'hidden lg:block',
                    'rounded-[24px]',
                    'absolute',
                    'transition-opacity duration-500 ease-in-out',
                    isHover ? 'opacity-100' : 'opacity-0'
                  )
                )}
                imageClassName="rounded-[24px]"
                src={secondaryImage}
                alt={`${name} hover image`}
                aspectRatio={width / height}
                fill
                sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 25vw"
              />
            )}
            <Image
              className={twMerge(
                clsx(
                  'rounded-[24px]',
                  'transition-opacity duration-500 ease-in-out',
                  secondaryImage && isHover ? 'opacity-0' : 'opacity-100'
                )
              )}
              imageClassName="rounded-[24px] object-contain"
              src={primaryImage}
              alt={name}
              aspectRatio={width / height}
              fill
              sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 25vw"
            />
          </div>
          {/* Mobile Images */}
          <div className="relative h-full w-full lg:hidden">
            <Image
              className={twMerge(clsx('rounded-[24px]'))}
              imageClassName="rounded-[24px] object-contain"
              src={primaryImage}
              alt={name}
              aspectRatio={mobileImageWidth / mobileImageHeight}
              fill
              sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 25vw"
            />
          </div>
        </>
      ) : (
        <div className="relative z-0 flex flex-col">
          <Image
            src={imageUrl ? imageUrl : '/akn-noimage.jpg'}
            alt={name}
            fill
            sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 25vw"
            className="h-full"
            aspectRatio={width / height}
          />
        </div>
      )}
    </Link>
  );
};
