import { Product } from "@akinon/next/types";

export const swanLogin = (session:any) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.login) {
    window._swan.login(session?.user?.pk)
  }
}

export const swanLogout = () => {
  if (typeof window !== 'undefined' && window._swan && window._swan.logout) {
    window._swan.logout();
  }
}

export const swanPageView = (url:string) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.screen) {
    window._swan.screen({ "screenName": url });
  }
}

export const swanProductView = (product:Pick<
  Product,
  'pk'
>) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productViewed) {
    window._swan.productViewed({
      "productId": product?.pk
      });
  }
}

export const swanProductClicked = (product:Pick<
  Product,
  'pk'
>) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productClicked) {
    window._swan.productClicked({
      "productId": product?.pk
    });
  }
}

export const swanAddWishlist = (product:Pick<
  Product,
  'pk'
>) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productAddedToWishlist) {
    window._swan.productAddedToWishlist({
      "productId": product?.pk
    });
  }
}

export const swanRemoveWishlist = (product:Pick<
  Product,
  'pk'
>) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productRemovedFromWishlist) {
    window._swan.productRemovedFromWishlist({
      "productId": product?.pk
    });
  }
}

export const swanPushAddToCart = (product: Product) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productAddedToAddTocart) {
    window._swan.productAddedToAddTocart({
      "productId": product?.pk,
      "quantity": product?.quantity || 1
    });
  }
}

export const swanRemoveFromCart = (product: Product) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productRemovedFromAddToCart) {
    window._swan.productRemovedFromAddToCart({
      "productId": product?.pk,
      "quantity": 0
    });
  }
}

export const swanShare = (pk:string, media:string ) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.share) {
    window._swan.share({
      "productId" : pk,
      "media": media || ''
      });
  }
}

export const swanForgotPassword = () => {
  if (typeof window !== 'undefined' && window._swan && window._swan.forgotPassword) {
    window._swan.forgotPassword({ "success": true });
  }
}

export const swanSearch = (searchTerm:string) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.search) {
    window._swan.search({
      "searchKeyword": searchTerm
    });
  }
}
