'use client';

import { Select } from '@theme/components';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export const SelectBox = (props) => {
  const { product, wrapperClassName, className, height, fontSize, selectedProduct, setProduct } = props;

  const variants = product?.extra_data?.variants

  const options = variants?.[0]?.options.map((option) => {
    if (Number(option?.product?.price) !== 0 || option?.product?.price !== null || option?.in_stock) {
      return {
        value: option.product.pk,
        price: option.product.price,
        retailPrice: option.product.price * 2,
        label: option.label,
        product: option.product,
      }
    }
  })

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = options.find(option => option.value === selectedValue);

    if (selectedOption) {
      const { product } = selectedOption;
      setProduct(product)
    }
  }

  return (
    <>
      <div className={twMerge(clsx('relative', options?.length > 1 ? "visible opacity-100" : "hidden"), wrapperClassName)}>
        <Select
          className={twMerge(
            clsx(
              'min-w-[95px] flex z-10 w-full bg-gray-25 min-h-auto items-center !px-1.5 py-0  border border-solid border-[#D9D9D9] rounded-[6px]',
              height ? `h-[${height}px]` : 'h-[32px]',
              fontSize ? `text-[${fontSize}]` : 'text-base',
            ),
            className
          )}
          selectedValue={selectedProduct?.pk}
          onChange={handleChange}
          options={options}
        />
      </div>
    </>
  );
};
